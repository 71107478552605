import React from 'react'
import Layout from "../components/App/Layout"
import OurServices from "../components/ForAll/OurServices"
import ForAllBannerImage from "../components/ForAll/ForAllBannerImage"
import Testimonials from '../components/Common/Testimonials'
import PageBannerBackground from '../components/Common/PageBannerBackground' 

const Therapies = () => {
    return (
        <Layout page="Therapies">
            <PageBannerBackground
                pageTitle="THERAPIES" 
                crumbs={[{page: "Home", url: "/"}]}      
                bgFileName="for-all/for-all-banner.jpg" 
            />
            <OurServices />
            <ForAllBannerImage />
            <Testimonials />
        </Layout>
    );
}

export default Therapies;