import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ForAllBannerImage = () => {
    
  return (
    <section className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <StaticImage
            src="../../assets/images/for-all/for-all-image.jpg"
            placeholder="blurred"
            alt="For All"
          />
        </div>
      </div>
    </section>
  )
}

export default ForAllBannerImage
