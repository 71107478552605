import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"
import starIcon from "../../assets/images/star-icon.png"
import ExploreReiki from "./ExploreReiki"
import { useStaticQuery, graphql, Link } from "gatsby"

const OurServices = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          videosBaseUrl
        }
      }
    }
  `)

  return (
    <div className="events-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="icon" /> Wellness
          </span>
          <h2>
            Connect with your body, mind and spirit. Improve the quality of your
            life.
          </h2>
          <p>
            Find new strength for everyday challenges, significant transitions,
            and trauma recovery. Embody the Self, heal and integrate injured
            parts of you. Cultivate a deeper, more compassionate understanding
            of yourself.
            <br />
            Rewire your brain and the nervous system to release negative
            patterns and embrace a more positive future. Find the service that’s
            right for you.
          </p>
        </div>

        <ExploreReiki />

        <div className="row">
          <div id="eft" className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
                <Link
                  href="/contact"
                  className="d-block"
                >
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + "/eft.mp4"}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                    ratio={0.85}
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                  <Link href="/contact" className="d-block">
                    EFT
                  </Link>
                </h3>
                <p>
                  Emotional Freedom Techniques is a form of counselling
                  intervention that draws on various theories of alternative
                  medicine – including acupuncture, neurolinguistic programming,
                  energy medicine, and Thought Field Therapy.
                </p>
                <div className="option-item">
                  <Link
                    href="/contact"
                    activeClassName="active"
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i> Book Now<span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div id="fengshui" className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
                <Link href="/contact" className="d-block" rel="noreferrer">
                  <StaticImage
                    src="../../assets/images/for-all/feng-shui.jpg"
                    placeholder="blurred"
                    alt="Feng Shui"
                    width={380}
                    height={320}
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                  <Link
                    href="/contact"
                    className="d-block"
                  >
                    Feng Shui
                  </Link>
                </h3>
                <p>
                  Feng shui, also known as Chinese geomancy, is a traditional
                  pseudoscientific practice originating from ancient China,
                  which claims to use energy forces to harmonise individuals
                  with their surrounding environment.
                </p>
                <div className="option-item">
                  <Link
                    href="/contact"
                    activeClassName="active"
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i> Book Now<span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices
