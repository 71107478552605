import React from "react"
import {useStaticQuery, graphql, Link} from 'gatsby'
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"
import starIcon from "../../assets/images/star-icon.png"

const ExploreReiki = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

  return (
    <React.Fragment>
      <div className="explore-learning-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="explore-learning-content">
                <span className="sub-title">
                  <img src={starIcon} alt="team" />
                  Our Therapies
                </span>
                <h2>Reiki Sessions</h2>
                <p>
                  Reiki is a Japanese form of alternative medicine called energy
                  healing. Reiki practitioners use a technique called palm
                  healing or hands-on healing. 
                  A "universal energy" to be transferred through the practitioner's palms to the patient to encourage emotional or physical healing.<br/>
                </p>
                <div className="option-item">
                  <Link
                    href="/contact"
                    activeClassName="active"
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i> Book Now<span></span>
                  </Link>
                </div>
              </div>
              
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="explore-learning-video">
                <VideoResponsivePlayer
                  url={data.site.siteMetadata.videosBaseUrl + '/explore_reiki.mp4'}
                  light={data.site.siteMetadata.videosBaseUrl + '/explore_reiki.png'}
                  controls={true}
                  playing={true}
                  loop={false}
                  muted={false}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExploreReiki
